import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
export default {
  props: {
    data: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_el_row = _resolveComponent("el-row");

      var _component_el_col = _resolveComponent("el-col");

      return _openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data, function (v, k) {
        return _openBlock(), _createBlock(_component_el_row, {
          align: "middle",
          justify: "center",
          key: "os_".concat(k),
          class: "std-padding_line-sm"
        }, {
          default: _withCtx(function () {
            return [_createVNode(_component_el_col, null, {
              default: _withCtx(function () {
                return [_createVNode(_component_el_row, {
                  align: "middle",
                  justify: "center",
                  class: "std-padding_line-sm"
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("h3", null, _toDisplayString(v.audioFormat), 1)];
                  }),
                  _: 2
                }, 1024), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.requirements, function (r, key) {
                  return _openBlock(), _createBlock(_component_el_row, {
                    align: "middle",
                    justify: "center",
                    key: "os_r_".concat(key),
                    style: {
                      "text-align": "center"
                    }
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("p", null, "- " + _toDisplayString(r), 1)];
                    }),
                    _: 2
                  }, 1024);
                }), 128))];
              }),
              _: 2
            }, 1024)];
          }),
          _: 2
        }, 1024);
      }), 128);
    };
  }
};